import React, { useContext, useState } from 'react';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { View, Text, Image } from 'react-native';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { paddingLeft, paddingRight } from '@src/styles';
import { useTranslation } from '@src/hooks/useTranslation';
import { Input } from '@src/components/shared-components/Input';
import { ADD_LOGGER_SCREENS } from '../constant';
import { enterCodeScreenStyles as styles } from '../styles';

export const EnterAssetCode = ({ navigation }) => {
    const { t } = useTranslation();
    const { setAsset } = useContext(AddLoggerContext);
    const [code, setCode] = useState('');

    const onConfirm = () => {
        setAsset(curAsset => ({ ...curAsset, number: code }));
        navigation.navigate(ADD_LOGGER_SCREENS.CHECK_ASSET_CODE);
    };

    const onScanCode = () => {
        navigation.navigate(ADD_LOGGER_SCREENS.SCAN_ASSET_CODE);
    };

    return (
        <>
            <View style={styles.wrapper}>
                <Text
                    style={styles.title}
                >
                    {t('ADD_LOGGER.ENTER_ASSET_CODE')}
                </Text>
                <View style={styles.inputWrapper}>
                    <Input
                        value={code}
                        onChangeText={(value) => setCode(value)}
                        placeholder="EX. AAA 00000 AF"
                        placeholderTextColor="#D7D7D7"
                    />
                </View>
                <View style={styles.imageWrapper}>
                    <Image
                        style={styles.image}
                        source={require('@assets/images/enter_asset_code.png')}
                    />
                </View>
            </View>
            <ButtonsBlock
                primaryBtnText={t('COMMON.CONFIRM')}
                primaryBtnOnPress={onConfirm}
                secondaryBtnText={t('COMMON.SCAN_CODE')}
                secondaryBtnOnPress={onScanCode}
                style={{ paddingLeft, paddingRight }}
            />
        </>
    );
};
