import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import FeatherIcons from '@expo/vector-icons/Feather';
import { paddingLeft, paddingRight, theme } from '@src/styles';

const styles = StyleSheet.create({
    container: {
        marginBottom: 44,
        paddingLeft,
        paddingRight,
    },
    fullFilled: {
        backgroundColor: theme.palette.primary.deepBlue,
    },
    hr: {
        backgroundColor: theme.palette.primary.deepBlue,
        height: 1,
        position: 'absolute',
        top: 20,
        transform: [{ translateY: -0.5 }],
    },
    step: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.primary.deepBlue,
        borderRadius: 20,
        borderWidth: 1,
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        height: 40,
        justifyContent: 'center',
        letterSpacing: 0.15,
        lineHeight: 0,
        marginBottom: 8,
        width: 40,
    },
    stepText: {
        color: theme.palette.primary.darkBlue,
    },
    stepTextFullFilled: {
        color: theme.palette.common.white,
    },
    stepWrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    stepper: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    wrapper: {
        position: 'relative',
    },
});

export const Stepper = ({ labels, stepperData }) => {
    return (
        <View style={styles.container}>
            <View style={[styles.wrapper, { marginLeft: 20, marginRight: 20, marginBottom: 8 }]}>
                <View style={[styles.hr, { width: '100%' }]} />
                <View style={styles.stepper}>
                    {labels.map((label, index) => {
                        return (
                            <View
                                key={label}
                                style={styles.stepWrapper}
                            >
                                <View
                                    style={[
                                        styles.step,
                                        index + 1 <= stepperData.curStep ? styles.fullFilled : {},
                                    ]}
                                >
                                    {index + 1 < stepperData.curStep ? (
                                        <FeatherIcons
                                            name="check"
                                            size={20}
                                            color="#fff"
                                        />
                                    ) : (
                                        <Text
                                            style={
                                                index + 1 === stepperData.curStep
                                                    ? styles.stepTextFullFilled
                                                    : styles.stepText
                                            }
                                        >
                                            {index + 1}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        );
                    })}
                </View>
            </View>
            <View style={styles.stepper}>
                {labels.map((label) => {
                    return (
                        <View
                            key={label}
                            style={[styles.stepWrapper, { width: 75 }]}
                        >
                            <Text style={{
                                fontSize: 11,
                                textAlign: 'center',
                            }}
                            >
                                {label}
                            </Text>
                        </View>
                    );
                })}
            </View>
        </View>
    );
};
