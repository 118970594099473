import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { AntDesign, MaterialIcons, Ionicons } from '@expo/vector-icons';
import { ASSET_TYPE_CODES, INSTANCE_TYPES } from '@src/constants';
import { useTranslation } from '@src/hooks/useTranslation';
import { theme } from '@src/styles';

export const STATUSES = {
    IDENTIFIED: 'identified',
    NOT_FOUND: 'not_found',
    ERROR: 'error',
    PAIRED: 'paired',
    PAIRED_SUCCESS: 'paired_success',
    REMOVED_SUCCESS: 'removed_success',
};

export const STATUS_ICONS = {
    DONE: 'done',
};

const marginLeft = 51;

const styles = StyleSheet.create({
    devider: {
        backgroundColor: theme.palette.common.white,
    },
    deviderSuccess: {
        backgroundColor: theme.palette.common.transparent,
    },
    deviderText: {
        color: theme.palette.secondary.main,
        fontFamily: 'RobotoMedium',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: '500',
        letterSpacing: 0.1,
        lineHeight: 24,
    },
    deviderTextSuccess: {
        color: theme.palette.common.white,
    },
    left: {
        marginRight: 20,
        minWidth: 65,
    },
    leftText: {
        fontFamily: 'RobotoMedium',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: '500',
        letterSpacing: 0.1,
        lineHeight: 24,
    },
    leftTextSuccess: {
        color: theme.palette.common.white,
    },
    right: {
        backgroundColor: theme.palette.secondary[100],
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        flexGrow: 1,
        paddingBottom: 8,
        paddingLeft: 21,
        paddingRight: 21,
        paddingTop: 8,
    },
    rightSuccess: {
        backgroundColor: theme.palette.common.white,
    },
    rightText: {
        color: theme.palette.primary.darkBlue,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.15,
        lineHeight: 24,
    },
    rightTextSuccess: {
        color: theme.palette.common.black,
    },
    row: {
        marginLeft: marginLeft + 20,
    },
    rowMarginBottom: {
        marginBottom: 8,
    },
    rowSuccess: {
        marginLeft,
    },
    subTitle: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.18,
        lineHeight: 24,
        marginTop: 16,
        paddingLeft: 50,
    },
    title: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: 22,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.18,
        lineHeight: 24,
        marginLeft: 5,
    },
    titleErrorMargin: {
        marginLeft: 10,
    },
    titleMargin: {
        marginLeft: 16,
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    wrapperError: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
    },
    wrapperPaired: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginLeft,
        width: '100%',
    },
    wrapperPairedMargin: {
        marginBottom: 39,
    },
});

type Props = {
    status: string,
    instanceType?: 'asset' | 'logger',
    assetNumber?: number,
    loggerNumber?: number,
    assetTypeCode? : string,
    iconStatus? : string,
    deviderTextCode? : string,
};

type PropsPaired = {
    assetNumber?: number,
    loggerNumber?: number,
    assetTypeCode? : string,
    iconStatus? : string,
    isSuccess? : boolean,
    deviderTextCode? : string,
};

const Paired = ({
    loggerNumber,
    assetNumber,
    assetTypeCode,
    iconStatus,
    deviderTextCode,
    isSuccess = false,
}: PropsPaired) => {
    const { t } = useTranslation();

    const loggerComponent = (
        <>
            <View style={styles.left}>
                <Text style={[styles.leftText, isSuccess ? styles.leftTextSuccess : {}]}>
                    {t('COMMON.LOGGER')}
                    :
                </Text>
            </View>
            <View style={[styles.right, isSuccess ? styles.rightSuccess : {}]}>
                <Text style={[styles.rightText, isSuccess ? styles.rightTextSuccess : {}]}>
                    {loggerNumber}
                </Text>
            </View>
        </>
    );

    const assetComponent = (
        <>
            <View style={styles.left}>
                <Text style={[styles.leftText, isSuccess ? styles.leftTextSuccess : {}]}>
                    {assetTypeCode === ASSET_TYPE_CODES.PALLET
                        ? t('COMMON.PALLET')
                        : t('COMMON.CONTAINER')}
                    :
                </Text>
            </View>
            <View style={[styles.right, isSuccess ? styles.rightSuccess : {}]}>
                <Text style={[styles.rightText, isSuccess ? styles.rightTextSuccess : {}]}>
                    {assetNumber}
                </Text>
            </View>
        </>
    );

    return (
        <>
            {!isSuccess && (
                <View style={[styles.wrapperPaired, styles.wrapperPairedMargin]}>
                    {iconStatus === STATUS_ICONS.DONE
                        ? <MaterialIcons name="done" size={48} color={theme.palette.primary.deepBlue} />
                        : <Ionicons name="warning-outline" size={48} color={theme.palette.common.yellow} />}
                    <Text style={[styles.title, styles.titleMargin]}>
                        {t('ADD_LOGGER.PAIRING_IDENTIFIED')}
                    </Text>
                </View>
            )}

            <View style={[
                styles.wrapperPaired,
                styles.row,
                isSuccess ? styles.rowSuccess : {},
                styles.rowMarginBottom,
            ]}
            >
                {assetComponent}
            </View>

            <View style={[
                styles.wrapperPaired,
                styles.row,
                isSuccess ? styles.rowSuccess : {},
                styles.rowMarginBottom,
            ]}
            >
                <View style={styles.left} />
                <View style={[
                    styles.right,
                    styles.devider,
                    isSuccess ? styles.deviderSuccess : {},
                ]}
                >
                    <Text style={[styles.deviderText, isSuccess ? styles.deviderTextSuccess : {}]}>
                        {t(deviderTextCode || 'ADD_LOGGER.PAIRED_WITH')}
                    </Text>
                </View>
            </View>

            <View style={[
                styles.wrapperPaired,
                styles.row,
                isSuccess ? styles.rowSuccess : {},
            ]}
            >
                {loggerComponent}
            </View>
        </>
    );
};

export const Status = ({
    status,
    assetNumber,
    loggerNumber,
    instanceType,
    assetTypeCode,
    iconStatus,
    deviderTextCode,
}: Props) => {
    const { t } = useTranslation();

    if (status === STATUSES.IDENTIFIED) {
        const getMessage = () => {
            if (instanceType === INSTANCE_TYPES.LOGGER) {
                return t('ADD_LOGGER.LOGGER_IDENTIFIED');
            } else if (assetTypeCode === ASSET_TYPE_CODES.PALLET) {
                return t('ADD_LOGGER.PALLET_IDENTIFIED');
            } else {
                return t('ADD_LOGGER.CONTAINER_IDENTIFIED');
            }
        };

        return (
            <View style={styles.wrapper}>
                <MaterialIcons name="done" size={48} color={theme.palette.primary.deepBlue} />
                <Text style={styles.title}>
                    {getMessage()}
                </Text>

            </View>
        );
    }

    if (status === STATUSES.NOT_FOUND) {
        return (
            <View style={styles.wrapper}>
                <AntDesign name="close" size={48} color="#D44848" />
                <Text style={styles.title}>
                    {instanceType === INSTANCE_TYPES.ASSET ? 'Pallet/container' : 'Logger'}
                    {'\n'}
                    not found in the system.
                </Text>
            </View>
        );
    }

    if (status === STATUSES.PAIRED) {
        return (
            <Paired
                loggerNumber={loggerNumber}
                assetNumber={assetNumber}
                assetTypeCode={assetTypeCode}
                iconStatus={iconStatus}
            />
        );
    }

    if (status === STATUSES.PAIRED_SUCCESS) {
        return (
            <Paired
                loggerNumber={loggerNumber}
                assetNumber={assetNumber}
                assetTypeCode={assetTypeCode}
                isSuccess
            />
        );
    }

    if (status === STATUSES.REMOVED_SUCCESS) {
        return (
            <Paired
                loggerNumber={loggerNumber}
                assetNumber={assetNumber}
                assetTypeCode={assetTypeCode}
                deviderTextCode={deviderTextCode}
                isSuccess
            />
        );
    }

    if (status === STATUSES.ERROR) {
        return (
            <View>
                <View style={styles.wrapperError}>
                    <AntDesign name="exclamationcircleo" size={40} color="#D44848" />
                    <Text style={[styles.title, styles.titleErrorMargin]}>
                        {t('COMMON.ERROR')}
                    </Text>
                </View>
                <Text style={styles.subTitle}>
                    {t('COMMON.ERROR_DESCRIPTION')}
                </Text>
            </View>
        );
    }

    return null;
};
