import { useTranslation } from '@src/hooks/useTranslation';
import { theme } from '@src/styles';
import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';

const styles = StyleSheet.create({
    image: {
        height: 114,
        width: 114,
    },
    text: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.25,
        lineHeight: 20,
        textAlign: 'center',
    },
    title: {
        fontSize: 24,
        letterSpacing: 0.18,
        lineHeight: 24,
        marginBottom: 16,
    },
    wrapper: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
});

export const NoAccess = () => {
    const { t } = useTranslation();

    return (
        <View style={styles.wrapper}>
            <Image
                style={styles.image}
                source={require('@assets/images/lock.png')}
            />
            <Text style={[styles.text, styles.title]}>{t('NO_ACCESS.TITLE')}</Text>
            <Text style={styles.text}>{t('NO_ACCESS.CONTACT')}</Text>
        </View>
    );
};
