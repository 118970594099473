import { theme } from '@src/styles';
import React, { useState } from 'react';
import { TextInput, StyleSheet, Text, View } from 'react-native';

export const styles = StyleSheet.create({
    error: {
        color: theme.palette.error.main,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginTop: 16,
    },
    focused: {
        borderColor: theme.palette.common.black,
    },
    input: {
        borderColor: theme.palette.common.secondaryGray,
        borderRadius: 4,
        borderWidth: 1,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        height: 55,
        letterSpacing: 0.15,
        padding: 15,
    },
    label: {
        color: theme.palette.common.black,
        fontFamily: 'RobotoMedium',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: '500',
        letterSpacing: 0.1,
        lineHeight: 24,
        marginBottom: 5,
    },
});

export const Input = ({ label = '', error = '', style = {}, styleWrapper = {}, ...props }) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <View style={styleWrapper}>
            {label && <Text style={styles.label}>{label}</Text>}
            <TextInput
                style={[styles.input, isFocused && styles.focused, style]}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholderTextColor={theme.palette.common.secondaryGray}
                {...props}
            />
            {error && <Text style={styles.error}>{error}</Text>}
        </View>
    );
};
