import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { ASSET_TYPE_CODES, INSTANCE_TYPES } from '@src/constants';
import { useTranslation } from '@src/hooks/useTranslation';
import { theme } from '@src/styles';

const styles = StyleSheet.create({
    devider: {
        backgroundColor: theme.palette.common.firstShadeOfGray,
        height: 1,
        marginBottom: 40,
        marginTop: 80,
        width: '100%',
    },
    text: {
        color: theme.palette.primary.darkBlue,
        fontFamily: 'Roboto',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.18,
        lineHeight: 24,
        textAlign: 'center',
    },
});

export const Description = ({ instanceType, assetTypeCode }) => {
    const { t } = useTranslation();

    const getMessage = () => {
        if (instanceType === INSTANCE_TYPES.LOGGER) {
            return t('REMOVE_LOGGER.LOGGER_NOT_PAIRED');
        } else if (assetTypeCode === ASSET_TYPE_CODES.PALLET) {
            return t('REMOVE_LOGGER.PALLET_NOT_PAIRED');
        } else {
            return t('REMOVE_LOGGER.CONTAINER_NOT_PAIRED');
        }
    };

    return (
        <>
            <View style={styles.devider} />
            <View>
                <Text style={styles.text}>{getMessage()}</Text>
            </View>
        </>
    );
};
