import React, { useContext, useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Linking, Dimensions } from 'react-native';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import AntDesignIcons from '@expo/vector-icons/AntDesign';
import { Button } from '@src/components/shared-components/Button';
import { SCREEN_NAMES } from '@src/Root/constant';
import { StoreContext } from '@src/Contexts/StoreContext';
import { useTranslation } from '@src/hooks/useTranslation';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { theme } from '@src/styles';

const styles = StyleSheet.create({
    close: {
        alignItems: 'center',
        backgroundColor: 'rgba(235, 235, 245, 0.60)',
        borderRadius: 15,
        display: 'flex',
        height: 30,
        justifyContent: 'center',
        width: 30,
    },
    closeWrapper: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 16,
    },
    companyName: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 24,
    },
    contactSupport: {
        borderBottomColor: 'rgba(0, 0, 0, 0.12)',
        borderBottomWidth: 1,
        paddingBottom: 24,
    },
    contactSupportText: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.15,
        lineHeight: 24,
    },
    menuItem: {
        alignItems: 'center',
        borderBottomColor: 'rgba(0, 0, 0, 0.12)',
        borderBottomWidth: 1,
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 24,
        paddingTop: 24,
    },
    menuItemText: {
        fontFamily: 'RobotoMedium',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: '500',
        letterSpacing: 1.25,
        lineHeight: 16,
        textTransform: 'uppercase',
    },
    menuWrapper: {
        borderTopColor: 'rgba(0, 0, 0, 0.12)',
        borderTopWidth: 1,
    },
    user: {
        marginBottom: 44,
        marginTop: 30,
    },
    userName: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'RobotoMedium',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 24,
        marginBottom: 8,
    },
});

export const SideBar = ({ state, navigation }) => {
    const insets = useSafeAreaInsets();
    const { userInfo, auth } = useContext(StoreContext);
    const { FlexibleRequest: getUserData } = useBackendEndpoints()?.requests || {};
    const { logout } = auth;
    const { t } = useTranslation();

    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        const getUser = async () => {
            if (!userInfo?.email) return;

            try {
                const { data: userData } = await getUserData(
                    'GET',
                    `/users/email/${userInfo.email}`,
                );

                if (!userData?.companyId) return;

                const { data: companyData } = await getUserData(
                    'GET',
                    `/companies/${userData.companyId}`,
                );

                setCompanyName(companyData.name);
            } catch (error) {
                console.log(error);
                if (error.response?.status === 401) {
                    logout();
                }
            }
        };

        getUser();
    }, [userInfo?.email]);

    return (
        <DrawerContentScrollView
            contentContainerStyle={{
                paddingTop: insets.top,
            }}
        >
            <View style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                // TODO: fix this height
                height: Dimensions.get('window').height - insets.top - 24, // 24 is paddingBottom
                paddingLeft: 32,
                paddingRight: 32,
                paddingBottom: 24,
            }}
            >
                <View>
                    <View style={styles.closeWrapper}>
                        <TouchableOpacity
                            style={styles.close}
                            onPress={() => navigation.closeDrawer()}
                        >
                            <AntDesignIcons name="close" size={16} color="#3A3A3C" />
                        </TouchableOpacity>
                    </View>

                    <View style={styles.user}>
                        <Text style={styles.userName}>{userInfo.name}</Text>
                        <Text style={styles.companyName}>{companyName}</Text>
                    </View>

                    {state.routes.length > 1 && (
                        <View style={styles.menuWrapper}>
                            {state.routes
                                .filter(r => r.name !== SCREEN_NAMES.ACTIONS)
                                .map((route) => {
                                    return (
                                        <TouchableOpacity
                                            key={route.key}
                                            style={styles.menuItem}
                                            onPress={() => {
                                                navigation.navigate(route.name);
                                            }}

                                        >
                                            <Text style={styles.menuItemText}>{t(`MENU_ITEMS.${route.name}`)}</Text>
                                        </TouchableOpacity>
                                    );
                                })}
                        </View>
                    )}
                </View>

                <View>
                    <View style={styles.contactSupport}>
                        <Text style={{ ...styles.contactSupportText, color: theme.palette.common.black }}>
                            {t('COMMON.CONTACT_SUPPORT')}
                        </Text>
                        <Text
                            style={{ ...styles.contactSupportText, color: theme.palette.primary.darkBlue }}
                            onPress={() => Linking.openURL('mailto:support@skycell.com')}
                        >
                            support@skycell.com
                        </Text>
                    </View>

                    <Button
                        variant="danger" onPress={() => {
                            navigation.navigate(SCREEN_NAMES.LOGIN);
                            navigation.closeDrawer();
                            logout();
                        }}
                    >
                        {t('COMMON.LOGOUT')}
                    </Button>
                </View>

            </View>
        </DrawerContentScrollView>
    );
};
