import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { SuccessScreen } from '@src/components/SuccessScreen';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { INSTANCE_TYPES } from '@src/constants';
import React, { useContext, useMemo } from 'react';

export const RemovalSuccess = ({ navigation, route }) => {
    const { asset, logger } = useContext(RemoveLoggerContext);
    const { type } = route.params || {};
    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);

    return (
        <SuccessScreen navigation={navigation}>
            <Status
                status={STATUSES.REMOVED_SUCCESS}
                assetNumber={instance.assetNumber}
                loggerNumber={instance.loggerNumber}
                assetTypeCode={instance.assetTypeCode}
                deviderTextCode="REMOVE_LOGGER.NOT_PAIRED_WITH"
            />
        </SuccessScreen>
    );
};
