import React, { useContext, useEffect, useMemo, useState } from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { INSTANCE_TYPES } from '@src/constants';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { getStatus } from '@src/lib';
import { STATUSES, STATUS_ICONS, Status } from '@src/components/shared-components/Status';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { paddingLeft, paddingRight, theme } from '@src/styles';
import { useTranslation } from '@src/hooks/useTranslation';
import { REMOVE_LOGGER_SCREENS } from '../constant';
import { Description } from './Description';
import { ConfirmModal } from './ConfirmModal';

const styles = StyleSheet.create({
    contentWrapper: {
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
    },
    spinnerWrapper: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
    title: {
        color: theme.palette.primary.darkBlue,
        fontFamily: 'Roboto',
        fontSize: 48,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 56,
        marginTop: 28,
        maxWidth: '100%',
        textAlign: 'center',
    },
    wrapper: {
        flex: 1,
        paddingLeft,
        paddingRight,
    },
    wrapperNoPadding: {
        // backgroundColor: 'yellow',
        flex: 1,
    },
});

const backScreen = REMOVE_LOGGER_SCREENS.ENTER_ASSET_OR_LOGGER_CODE;

export const CheckCode = ({ navigation, route }) => {
    const { type } = route.params || {};
    const { t } = useTranslation();
    const { logger, asset, setLogger, setAsset, stepper } = useContext(RemoveLoggerContext);
    const { checkPairingByLogger, checkPairingByAsset } = useBackendEndpoints().requests;
    const [status, setStatus] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);
    const setInstance = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? setAsset : setLogger),
        [type, setAsset, setLogger],
    );
    const checkPairing = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? checkPairingByAsset : checkPairingByLogger),
        [type, checkPairingByAsset, checkPairingByLogger],
    );

    const checkInstance = async () => {
        if (!instance.number) return;
        const result = await checkPairing(instance.number);

        if (result && result.data) {
            const { data } = result;

            setInstance({ ...instance, ...data });
            getStatus({ type, data, setStatus });
        } else {
            setStatus(STATUSES.ERROR);
        }
    };

    useEffect(() => {
        checkInstance();
    }, [type]);

    const goBack = () => {
        stepper.prevStep();
        navigation.navigate(backScreen);
    };

    const openModal = () => {
        setIsModalVisible(true);
    };

    const onModalClose = () => {
        setIsModalVisible(false);
    };

    if (status === STATUSES.IDENTIFIED) {
        return (
            <View style={styles.wrapper}>
                <View style={styles.contentWrapper}>
                    <Status status={STATUSES.IDENTIFIED} assetTypeCode={asset.assetTypeCode} instanceType={type} />
                    <Text style={styles.title}>{instance.number}</Text>
                    <Description assetTypeCode={asset.assetTypeCode} instanceType={type} />
                </View>
                <ButtonsBlock
                    secondaryBtnText={t('COMMON.BACK')}
                    secondaryBtnOnPress={goBack}
                />
            </View>
        );
    }

    if (status === STATUSES.NOT_FOUND) {
        return (
            <View style={styles.wrapper}>
                <View style={styles.contentWrapper}>
                    <Status status={STATUSES.NOT_FOUND} instanceType={type} />
                    <Text style={styles.title}>{instance.number}</Text>
                </View>
                <ButtonsBlock
                    primaryBtnText={t('COMMON.TRY_AGAIN')}
                    primaryBtnOnPress={goBack}
                />
            </View>
        );
    }

    if (status === STATUSES.PAIRED) {
        return (
            <View style={styles.wrapperNoPadding}>
                <View style={styles.contentWrapper}>
                    <Status
                        status={STATUSES.PAIRED}
                        assetNumber={instance.assetNumber}
                        loggerNumber={instance.loggerNumber}
                        assetTypeCode={asset.assetTypeCode}
                        iconStatus={STATUS_ICONS.DONE}
                    />
                </View>

                <ConfirmModal
                    isVisible={isModalVisible}
                    onClose={onModalClose}
                    type={type}
                    navigation={navigation}
                    setStatus={setStatus}
                />

                <ButtonsBlock
                    primaryBtnText={t('COMMON.REMOVE')}
                    secondaryBtnText={t('COMMON.BACK')}
                    primaryBtnOnPress={openModal}
                    secondaryBtnOnPress={goBack}
                    style={{ paddingLeft, paddingRight }}
                />
            </View>
        );
    }

    if (status === STATUSES.ERROR) {
        return (
            <View style={styles.wrapper}>
                <View style={styles.contentWrapper}>
                    <Status status={STATUSES.ERROR} />
                </View>
                <ButtonsBlock
                    secondaryBtnText={t('COMMON.TRY_AGAIN')}
                    secondaryBtnOnPress={goBack}
                />
            </View>
        );
    }

    return (
        <View style={styles.spinnerWrapper}>
            <ActivityIndicator size="large" color={theme.palette.primary.deepBlue} />
        </View>
    );
};
