import React, { useContext } from 'react';
import { Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { SCREEN_NAMES } from '@src/Root/constant';
import { theme } from '@src/styles';
import { StoreContext } from '@src/Contexts/StoreContext';

const styles = StyleSheet.create({
    image: {
        height: 25,
        width: 25 },
    text: {
        color: theme.palette.common.white,
        marginLeft: 8,
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginLeft: 16,
    },
});

export const Logo = () => {
    const { layout } = useContext(StoreContext);
    const { navigation } = layout;

    return (

        <TouchableOpacity
            style={styles.wrapper}
            onPress={() => navigation?.reset({ index: 0, routes: [{ name: SCREEN_NAMES.ACTIONS }] })}
        >
            <Image
                style={styles.image}
                source={require('@assets/images/logo.png')}
            />
            <Text style={styles.text}>SkyTrack</Text>
        </TouchableOpacity>
    );
};
