import React, { useContext, useState } from 'react';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { View, Text, Image } from 'react-native';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { paddingLeft, paddingRight } from '@src/styles';
import { ASSET_TYPE_CODES } from '@src/constants';
import { useTranslation } from '@src/hooks/useTranslation';
import { Input } from '@src/components/shared-components/Input';
import { ADD_LOGGER_SCREENS } from '../constant';
import { enterCodeScreenStyles as styles } from '../styles';

export const EnterLoggerCode = ({ navigation }) => {
    const { t } = useTranslation();
    const { asset, setLogger } = useContext(AddLoggerContext);
    const [code, setCode] = useState('');

    const onConfirm = () => {
        setLogger(curLogger => ({ ...curLogger, number: code }));
        navigation.navigate(ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE);
    };

    const onScanCode = () => {
        navigation.navigate(ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE);
    };

    return (
        <>
            <View style={styles.wrapper}>
                <Text
                    style={styles.title}
                >
                    {t('ADD_LOGGER.ENTER_LOGGER_CODE')}
                </Text>
                <View style={styles.inputWrapper}>
                    <Input
                        value={code}
                        onChangeText={(value) => setCode(value)}
                        placeholder="EX. 1456 3456 6464 8754"
                        placeholderTextColor="#D7D7D7"
                    />
                </View>
                <View style={styles.imageWrapper}>
                    {asset.assetTypeCode === ASSET_TYPE_CODES.CONTAINER && (
                        <Image
                            style={[styles.image, styles.imageContainer]}
                            source={require('@assets/images/enter_logger_code_container.png')}
                        />
                    )}
                    {asset.assetTypeCode === ASSET_TYPE_CODES.PALLET && (
                        <Image
                            style={styles.image}
                            source={require('@assets/images/enter_logger_code_pallet.png')}
                        />
                    )}
                </View>
            </View>
            <ButtonsBlock
                primaryBtnText={t('COMMON.CONFIRM')}
                primaryBtnOnPress={onConfirm}
                secondaryBtnText={t('COMMON.SCAN_CODE')}
                secondaryBtnOnPress={onScanCode}
                style={{ paddingLeft, paddingRight }}
            />
        </>
    );
};
