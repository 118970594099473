import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import {
    ResponseType,
    makeRedirectUri,
    useAuthRequest,
    useAutoDiscovery,
    exchangeCodeAsync,
    fetchUserInfoAsync,
    dismiss,
} from 'expo-auth-session';
import * as SecureStore from 'expo-secure-store';

import { ACCESS_TOKEN, USER_INFO } from '@src/constants';
import { url, realm, clientId } from '@src/constants/env';

import {
    RequestStatus,
    StoreContextInterface,
    UserInfo,
    Layout,
} from './dataTypes';

import {
    initialExtendedProfile,
    initialUserInfo,
    initialLayout,
} from './lib';

export const StoreContext = createContext<StoreContextInterface>({
    auth: {
        accessToken: '',
        login: () => {},
        logout: () => {},
        isLogin: false,
    },
    userInfo: initialUserInfo,
    layout: initialLayout,
    setLayout: () => {},
});

WebBrowser.maybeCompleteAuthSession();

export const Store = ({ children }) => {
    const discovery = useAutoDiscovery(`${url}realms/${realm}`);
    const [accessToken, setAccessToken] = useState('');
    const [status, setStatus] = useState<RequestStatus>('INIT');
    const [isLogin, setIsLogin] = useState(false);
    const [extendedProfile, setExtendedProfile] = useState<any>(initialExtendedProfile);
    const [layout, setLayout] = useState<Layout>(initialLayout);

    const redirectUri = makeRedirectUri({
        scheme: 'skytrack',
        path: 'Actions',
    });

    const [request, , promptAsync] = useAuthRequest(
        {
            responseType: ResponseType.Code,
            clientId,
            redirectUri,
            scopes: ['openid', 'profile'],
        },
        discovery,
    );

    const login = async () => {
        setIsLogin(true);
        if (!request) return;
        try {
            const codeResponse = await promptAsync();

            if (request && codeResponse?.type === 'success' && discovery) {
                const exchangeCodeData = await exchangeCodeAsync(
                    {
                        clientId,
                        code: codeResponse.params.code,
                        extraParams: request.codeVerifier ? { code_verifier: request.codeVerifier } : undefined,
                        redirectUri,
                    },
                    discovery,
                );

                const { accessToken } = exchangeCodeData;

                setAccessToken(accessToken);

                const userInfo = await fetchUserInfoAsync({ accessToken }, discovery);

                setExtendedProfile(userInfo);

                const accessTokenStorage = JSON.stringify(accessToken);
                const userInfoStorage = JSON.stringify(userInfo);

                if (Platform.OS !== 'web') {
                    SecureStore.setItemAsync(ACCESS_TOKEN, accessTokenStorage);
                    SecureStore.setItemAsync(USER_INFO, userInfoStorage);
                } else if (Platform.OS === 'web') {
                    localStorage.setItem(ACCESS_TOKEN, accessTokenStorage);
                    localStorage.setItem(USER_INFO, userInfoStorage);
                }

                setStatus('SUCCESS');
                setIsLogin(false);
            }
        } catch (error) {
            console.log(error);
            setStatus('FAILURE');
        }
    };

    const logout = useCallback(async () => {
        setAccessToken('');
        setExtendedProfile(initialExtendedProfile);
        setStatus('INIT');

        try {
            if (Platform.OS !== 'web') {
                SecureStore.deleteItemAsync(ACCESS_TOKEN);
                SecureStore.deleteItemAsync(USER_INFO);
            } else if (Platform.OS === 'web') {
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(USER_INFO);
            }

            dismiss();
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (accessToken && extendedProfile) return;

        if (Platform.OS !== 'web') {
            SecureStore.getItemAsync(ACCESS_TOKEN).then((accessTokenStorage) => {
                if (accessTokenStorage) {
                    setAccessToken(JSON.parse(accessTokenStorage));
                }
            });

            SecureStore.getItemAsync(USER_INFO).then((userInfoStorage) => {
                if (userInfoStorage) {
                    setExtendedProfile(JSON.parse(userInfoStorage));
                }
            });
        } else if (Platform.OS === 'web') {
            const accessTokenStorage = localStorage.getItem(ACCESS_TOKEN);
            const userInfoStorage = localStorage.getItem(USER_INFO);

            if (accessTokenStorage) {
                setAccessToken(JSON.parse(accessTokenStorage));
            }

            if (userInfoStorage) {
                setExtendedProfile(JSON.parse(userInfoStorage));
            }
        }
    }, []);

    const userInfo: UserInfo = useMemo(() => {
        if (!extendedProfile.email) {
            return initialUserInfo;
        }

        return extendedProfile;
    }, [extendedProfile]);

    const contextValue = useMemo(() => ({
        auth: {
            accessToken,
            login,
            logout,
            isLogin,
        },
        userInfo,
        layout,
        setLayout,
    }), [userInfo, accessToken, login, logout, layout, isLogin]);

    return (
        <>
            <StoreContext.Provider value={contextValue}>
                {children}
            </StoreContext.Provider>
            {/* {(!accessToken || result?.type !== 'success')
            && (
                <Login login={login} />
            )}

            {(accessToken || result?.type === 'success')
            && (
                <StoreContext.Provider value={contextValue}>
                    {children}
                </StoreContext.Provider>
            )} */}

            {/* <AuthContext.Provider value={contextValue}>
                {children}
            </AuthContext.Provider> */}

            {/* {
                !isAuthLoading && status === 'SUCCESS' && (
                    <AuthContext.Provider
                        value={{
                            userInfo,
                            token,
                            logout: keycloak ? keycloak.logout : () => {},
                        }}
                    >
                        {children}
                    </AuthContext.Provider>
                )
            } */}
            {/* {
                !isAuthLoading && status === 'FAILURE' && (
                    <StatusInfo
                        status={status}
                        error="An Error happened during login"
                    />
                )
            }
            {
                (status === 'PENDING') && (
                    <StatusInfo
                        status={status}
                        isAuthLoading={isAuthLoading}
                    />
                )
            } */}
        </>
    );
};
