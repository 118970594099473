import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { theme } from '@src/styles';

const styles = StyleSheet.create({
    spinnerWrapper: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
});

export const SpinnerScreen = () => {
    return (
        <View style={styles.spinnerWrapper}>
            <ActivityIndicator size="large" color={theme.palette.primary.deepBlue} />
        </View>
    );
};
