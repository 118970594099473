import React, { useEffect, useState, useContext, useMemo } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { paddingLeft, paddingRight, theme } from '@src/styles';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { useTranslation } from '@src/hooks/useTranslation';
import { INSTANCE_TYPES } from '@src/constants';
import { getStatus } from '@src/lib';
import { SpinnerScreen } from '@src/components/SpinnerScreen';
import { ADD_LOGGER_SCREENS } from '../constant';

const nextScreenAsset = ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE;
const nextScreenLogger = ADD_LOGGER_SCREENS.PAIRING_CONFIRM;
const backScreenAsset = ADD_LOGGER_SCREENS.ENTER_ASSET_CODE;
const backScreenLogger = ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE;

const styles = StyleSheet.create({
    contentWrapper: {
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
    },
    title: {
        color: theme.palette.primary.darkBlue,
        fontFamily: 'Roboto',
        fontSize: 48,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 56,
        marginTop: 28,
        maxWidth: '100%',
        overflow: 'hidden',
        textAlign: 'center',
    },
    wrapper: {
        flex: 1,
        paddingLeft,
        paddingRight,
    },
    wrapperNoPadding: {
        flex: 1,
    },
});

type Props = {
    // TODO: fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigation: any,
    type: 'asset' | 'logger',
};

const CheckAssetOrLogger = ({ navigation, type = 'asset' }: Props) => {
    const { t } = useTranslation();
    const { asset, setAsset, logger, setLogger, stepper } = useContext(AddLoggerContext);
    const [status, setStatus] = useState(null);
    const { checkPairingByAsset, checkPairingByLogger } = useBackendEndpoints().requests;

    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);
    const setInstance = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? setAsset : setLogger),
        [type, setAsset, setLogger],
    );
    const backScreen = useMemo(() => (type === INSTANCE_TYPES.ASSET ? backScreenAsset : backScreenLogger), [type]);
    const nextScreen = useMemo(() => (type === INSTANCE_TYPES.ASSET ? nextScreenAsset : nextScreenLogger), [type]);
    const checkPairing = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? checkPairingByAsset : checkPairingByLogger),
        [type, checkPairingByAsset, checkPairingByLogger],
    );

    const checkInstance = async () => {
        if (!instance.number) return;
        const result = await checkPairing(instance.number);

        if (result && result.data) {
            const { data } = result;

            setInstance({ ...instance, ...data });
            getStatus({ type, data, setStatus });
        } else {
            setStatus(STATUSES.ERROR);
        }
    };

    useEffect(() => {
        checkInstance();
    }, [instance.number]);

    if (status === STATUSES.IDENTIFIED) {
        return (
            <View style={styles.wrapper}>
                <View style={styles.contentWrapper}>
                    <Status status={STATUSES.IDENTIFIED} assetTypeCode={asset.assetTypeCode} instanceType={type} />
                    <Text style={styles.title}>{instance.number}</Text>
                </View>
                <ButtonsBlock
                    primaryBtnText={t('COMMON.NEXT')}
                    primaryBtnOnPress={() => {
                        stepper.nextStep();
                        navigation.navigate(nextScreen);
                    }}
                    secondaryBtnText={t('COMMON.SCAN_ANOTHER')}
                    secondaryBtnOnPress={() => navigation.navigate(backScreen)}
                />
            </View>
        );
    }

    if (status === STATUSES.NOT_FOUND) {
        return (
            <View style={styles.wrapper}>
                <View style={styles.contentWrapper}>
                    <Status status={STATUSES.NOT_FOUND} instanceType={type} />
                    <Text style={styles.title}>{instance.number}</Text>
                </View>
                <ButtonsBlock
                    primaryBtnText={t('COMMON.TRY_AGAIN')}
                    primaryBtnOnPress={() => navigation.navigate(backScreen)}
                />
            </View>
        );
    }

    if (status === STATUSES.PAIRED) {
        return (
            <View style={styles.wrapperNoPadding}>
                <View style={styles.contentWrapper}>
                    <Status
                        status={STATUSES.PAIRED}
                        assetNumber={instance.assetNumber}
                        loggerNumber={instance.loggerNumber}
                        assetTypeCode={asset.assetTypeCode}
                    />
                </View>
                <ButtonsBlock
                    primaryBtnText={t('COMMON.REMOVE')}
                    primaryBtnOnPress={() => {
                        alert('Not implemented yet');
                    }}
                    secondaryBtnText={t('COMMON.BACK')}
                    secondaryBtnOnPress={() => navigation.navigate(backScreen)}
                    style={{ paddingLeft, paddingRight }}
                />
            </View>
        );
    }

    if (status === STATUSES.ERROR) {
        return (
            <View style={styles.wrapper}>
                <View style={styles.contentWrapper}>
                    <Status status={STATUSES.ERROR} />
                </View>
                <ButtonsBlock
                    secondaryBtnText={t('COMMON.TRY_AGAIN')}
                    secondaryBtnOnPress={() => navigation.navigate(backScreen)}
                />
            </View>
        );
    }

    return (
        <SpinnerScreen />
    );
};

export const CheckAsset = (props) => (
    <CheckAssetOrLogger type="asset" {...props} />
);

export const CheckLogger = (props) => (
    <CheckAssetOrLogger type="logger" {...props} />
);
