import * as React from 'react';
import { Dimensions } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator, DrawerToggleButton } from '@react-navigation/drawer';
import { SideBar } from '@src/components/Header/SideBar';
import { Logo } from '@src/components/Header/Logo';
import { useTranslation } from '@src/hooks/useTranslation';
import { useContext, useMemo } from 'react';
import { StoreContext } from '@src/Contexts/StoreContext';
import { Home } from '@src/Home';
import { Login } from '@src/Login';
import { AddLoggerPath } from '@src/AddLogger/AddLoggerPath';
import { RemoveLoggerPath } from '@src/RemoveLogger/RemoveLoggerPath';
import { theme } from '@src/styles';
import { NoAccess } from '@src/NoAccess';
import { SpinnerScreen } from '@src/components/SpinnerScreen';
import { SCREEN_NAMES } from './constant';

function HeaderRight() {
    return (
        <DrawerToggleButton tintColor="#fff" />
    );
}

const Drawer = createDrawerNavigator();

function MyDrawer() {
    const { t } = useTranslation();
    const { auth, layout, userInfo } = useContext(StoreContext);
    const { accessToken, isLogin } = auth;
    const hasRole = userInfo?.groups?.includes('ASSET_PAIRING');

    const screens = useMemo(() => {
        if (isLogin) {
            return (
                <Drawer.Screen
                    name={SCREEN_NAMES.NO_ACCESS}
                    component={SpinnerScreen}
                    options={{ title: t(`MENU_ITEMS.${SCREEN_NAMES.ACTIONS}`) }}
                />
            );
        } else if (!accessToken) {
            return (
                <Drawer.Screen
                    name={SCREEN_NAMES.LOGIN}
                    component={Login}
                    options={{ title: t(`MENU_ITEMS.${SCREEN_NAMES.LOGIN}`) }}
                />
            );
        } else if (accessToken && !hasRole) {
            return (
                <Drawer.Screen
                    name={SCREEN_NAMES.NO_ACCESS}
                    component={NoAccess}
                    options={{ title: t(`MENU_ITEMS.${SCREEN_NAMES.ACTIONS}`) }}
                />
            );
        } else if (accessToken && hasRole) {
            return (
                <>
                    <Drawer.Screen
                        name={SCREEN_NAMES.ACTIONS}
                        component={Home}
                        options={{ title: t(`MENU_ITEMS.${SCREEN_NAMES.ACTIONS}`) }}
                    />
                    <Drawer.Screen
                        name={SCREEN_NAMES.ADD_LOGGER}
                        component={AddLoggerPath}
                        options={{ title: t(`MENU_ITEMS.${SCREEN_NAMES.ADD_LOGGER}`) }}
                    />
                    <Drawer.Screen
                        name={SCREEN_NAMES.REMOVE_LOGGER}
                        component={RemoveLoggerPath}
                        options={{ title: t(`MENU_ITEMS.${SCREEN_NAMES.REMOVE_LOGGER}`) }}
                    />
                </>
            );
        }
    }, [accessToken, userInfo, hasRole]);

    return (
        <Drawer.Navigator
            screenOptions={{
                headerShown: !!accessToken && !layout.isFullScreen,
                headerLeft: Logo,
                headerRight: HeaderRight,
                headerStyle: {
                    backgroundColor: theme.palette.primary.darkBlue,
                },
                headerTitleStyle: {
                    color: theme.palette.common.white,
                },
                drawerType: 'front',
                headerTitleAlign: 'center',
                drawerStyle: {
                    width: Dimensions.get('window').width * 0.85,
                },
                sceneContainerStyle: {
                    backgroundColor: theme.palette.common.white,
                },
            }}
            drawerContent={SideBar}
        >
            {screens}
        </Drawer.Navigator>
    );
}

export const Root = () => {
    return (
        <NavigationContainer>
            <MyDrawer />
        </NavigationContainer>
    );
};
