import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SCREEN_NAMES } from '@src/Root/constant';
import { theme } from '@src/styles';
import { EnterCode } from './EnterCode';
import { REMOVE_LOGGER_SCREENS } from './constant';
import { CheckCode } from './CheckCode';
import { RemovalSuccess } from './RemovalSuccess';

const Stack = createNativeStackNavigator();

export const RemoveLoggerStack = () => {
    return (
        <Stack.Navigator screenOptions={{
            title: SCREEN_NAMES.ADD_LOGGER,
            headerShown: false,
            contentStyle: {
                backgroundColor: theme.palette.common.white,
            },
        }}
        >
            <Stack.Screen name={REMOVE_LOGGER_SCREENS.ENTER_ASSET_OR_LOGGER_CODE} component={EnterCode} />
            <Stack.Screen name={REMOVE_LOGGER_SCREENS.CHECK_CODE} component={CheckCode} />
            <Stack.Screen name={REMOVE_LOGGER_SCREENS.REMOVAL_SUCCESS} component={RemovalSuccess} />
        </Stack.Navigator>
    );
};
