import React, { useContext, useState } from 'react';
import { View, Text, Image, ActivityIndicator } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from '@src/hooks/useTranslation';
import { paddingLeft, paddingRight, theme } from '@src/styles';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { ASSET_TYPE_CODES } from '@src/constants';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { enterCodeScreenStyles as styles } from '../styles';
import { ADD_LOGGER_SCREENS } from '../constant';

export const PairingConfirm = ({ navigation }) => {
    const { t } = useTranslation();
    const { asset, logger } = useContext(AddLoggerContext);
    const { loggerPairing } = useBackendEndpoints().requests;
    const [isLoading, setIsLoading] = useState(false);

    const onConfirm = async () => {
        setIsLoading(true);
        const result = await loggerPairing(asset.assetId, logger.number);

        if (result && result.status === 201) {
            navigation.navigate(ADD_LOGGER_SCREENS.PAIRING_SUCCESS);
        } else {
            // TODO: redirect to ERROR screen
        }
        setIsLoading(false);
    };

    const onDismiss = () => {
        navigation.navigate(ADD_LOGGER_SCREENS.ENTER_ASSET_CODE);
    };

    return (
        <>
            <View style={[styles.wrapper, { paddingTop: 20 }]}>
                <Text
                    style={styles.title}
                >
                    {t(asset.assetTypeCode === ASSET_TYPE_CODES.CONTAINER
                        ? 'ADD_LOGGER.ATTACH_LOGGER_INSIDE_CONTAINER'
                        : 'ADD_LOGGER.ATTACH_LOGGER_TO_PALLET')}
                </Text>
                <View style={[styles.imageWrapper, { marginTop: 58 }]}>
                    {asset.assetTypeCode === ASSET_TYPE_CODES.CONTAINER && (
                        <Image
                            style={{ width: 326, height: 206 }}
                            source={require('@assets/images/place_logger_inside_container.png')}
                        />
                    )}
                    {asset.assetTypeCode === ASSET_TYPE_CODES.PALLET && (
                        <Image
                            style={{ width: 344, height: 254 }}
                            source={require('@assets/images/place_logger_to_pallet.png')}
                        />
                    )}
                </View>
            </View>
            <ButtonsBlock
                primaryBtnText={isLoading
                    ? <ActivityIndicator size="small" color={theme.palette.common.white} />
                    : t('COMMON.CONFIRM')}
                isPrimaryBtnDisabled={isLoading}
                primaryBtnOnPress={onConfirm}
                secondaryBtnText={t('COMMON.DISMISS')}
                secondaryBtnOnPress={onDismiss}
                style={{ paddingLeft, paddingRight }}
            />
        </>
    );
};
