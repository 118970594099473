import { i18n, t as trans } from '@src/i18n';

export const useTranslation = () => {
    const t = (code) => {
        const text = trans(code);

        return text.replace(/\\n/g, '\n');
    };

    return (
        { i18n, t }
    );
};
