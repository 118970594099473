export const ADD_LOGGER_SCREENS = {
    ENTER_ASSET_CODE: 'ENTER_ASSET_CODE',
    SCAN_ASSET_CODE: 'SCAN_ASSET_CODE',
    CHECK_ASSET_CODE: 'CHECK_ASSET_CODE',
    ENTER_LOGGER_CODE: 'ENTER_LOGGER_CODE',
    CHECK_LOGGER_CODE: 'CHECK_LOGGER_CODE',
    SCAN_LOGGER_CODE: 'SCAN_LOGGER_CODE',
    PAIRING_CONFIRM: 'PAIRING_CONFIRM',
    PAIRING_SUCCESS: 'PAIRING_SUCCESS',
};
