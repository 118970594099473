import React, { useState, useEffect, useContext } from 'react';
import { Text, View, StyleSheet, Button } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { paddingLeft, paddingRight } from '@src/styles';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useTranslation } from '@src/hooks/useTranslation';
import { ADD_LOGGER_SCREENS } from '../constant';

const styles = StyleSheet.create({
    title: {
        color: '#000',
        fontFamily: 'Roboto',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.18,
        lineHeight: 24,
        marginBottom: 12,
        textAlign: 'center',
    },
    wrapper: {
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        paddingLeft,
        paddingRight,
    },
});

export const ScanLoggerCode = ({ navigation }) => {
    const { t } = useTranslation();
    const { setLogger } = useContext(AddLoggerContext);
    const [hasPermission, setHasPermission] = useState(null);
    const [scanned, setScanned] = useState(false);

    const onCodeScanned = (code) => {
        setLogger(curLogger => ({ ...curLogger, number: code }));
        navigation.navigate(ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE);
    };

    useEffect(() => {
        const getBarCodeScannerPermissions = async () => {
            const { status } = await BarCodeScanner.requestPermissionsAsync();

            setHasPermission(status === 'granted');
        };

        getBarCodeScannerPermissions();
    }, []);

    const handleBarCodeScanned = ({ data }) => {
        setScanned(true);
        onCodeScanned(data);
    };

    if (hasPermission === null) {
        return <Text>{t('COMMON.REQUESTING_CAMERA')}</Text>;
    } else if (hasPermission === false) {
        return <Text>{t('COMMON.NO_ACCESS_CAMERA')}</Text>;
    }

    return (
        <>
            <Text style={styles.title}>
                {t('ADD_LOGGER.SCAN_LOGGER_CODE')}
            </Text>
            <View style={styles.wrapper}>
                <BarCodeScanner
                    onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                    style={StyleSheet.absoluteFillObject}
                />
                {scanned && <Button title="Tap to Scan Again" onPress={() => setScanned(false)} />}

            </View>
            <ButtonsBlock
                primaryBtnText={t('COMMON.ENTER_MANUALLY')}
                primaryBtnOnPress={() => navigation.navigate(ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE)}
                secondaryBtnText={t('COMMON.BACK')}
                secondaryBtnOnPress={() => navigation.goBack()}
                style={{ paddingLeft, paddingRight }}
            />
        </>
    );
};
