import { StyleSheet } from 'react-native';
import { paddingLeft, paddingRight, theme } from '@src/styles';

export const enterCodeScreenStyles = StyleSheet.create({
    title: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.18,
        lineHeight: 24,
        textAlign: 'center',
    },
    wrapper: {
        flexGrow: 1,
        paddingLeft,
        paddingRight,
    },
});
