import React, { useContext, useEffect } from 'react';
import { StyleSheet, Text, ScrollView, View, Image } from 'react-native';
import { paddingLeft, paddingRight } from '@src/styles';
import { Button } from '@src/components/shared-components/Button';
import { useTranslation } from '@src/hooks/useTranslation';
import { SCREEN_NAMES } from '@src/Root/constant';
import { StoreContext } from '@src/Contexts/StoreContext';

const styles = StyleSheet.create({
    block: {
        width: '100%',
    },
    image: {
        height: 110,
        width: 110,
    },
    imageWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 24,
        marginTop: 13,
    },
    text: {
        color: '#000',
        fontFamily: 'Roboto',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.18,
        lineHeight: 24,
        textAlign: 'center',
    },
    title: {
        fontSize: 24,
        marginBottom: 5,
    },
    wrapper: {
        alignItems: 'center',
        borderBottomColor: '#939393',
        borderBottomWidth: 1,
        flex: 1,
        justifyContent: 'flex-start',
        marginBottom: 70,
        marginLeft: paddingLeft,
        marginRight: paddingRight,
        paddingTop: 48,
    },
});

const blocks = [
    {
        title: 'MENU_ITEMS.ADD_LOGGER',
        description: 'MAIN_PAGE.ADD_LOGGER_DESC',
        imageUrl: require('@assets/images/home_add_logger.png'),
        btnVariant: 'primary',
        btnText: 'Add',
        btnUrl: SCREEN_NAMES.ADD_LOGGER,
    },
    {
        title: 'MENU_ITEMS.REMOVE_LOGGER',
        description: 'MAIN_PAGE.REMOVE_LOGGER_DESC',
        imageUrl: require('@assets/images/home_remove_logger.png'),
        btnVariant: 'secondary',
        btnText: 'Remove',
        btnUrl: SCREEN_NAMES.REMOVE_LOGGER,
        customStyles: {
            marginTop: 83,
        },
    },
];

export const Home = ({ navigation }) => {
    const { t } = useTranslation();
    const { layout, setLayout } = useContext(StoreContext);

    useEffect(() => {
        setLayout({ ...layout, navigation });
    }, [navigation]);

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            {blocks.map(({ title, description, btnVariant, btnText, btnUrl, imageUrl, customStyles }) => (
                <View style={[styles.block, customStyles]} key={title}>
                    <Text style={[styles.text, styles.title]}>{t(title)}</Text>
                    <Text style={styles.text}>{t(description)}</Text>
                    <View style={styles.imageWrapper}>
                        <Image
                            style={styles.image}
                            source={imageUrl}
                        />

                    </View>
                    <Button
                        variant={btnVariant}
                        onPress={() => navigation.navigate(btnUrl)}
                    >
                        {btnText}
                    </Button>
                </View>
            ))}

        </ScrollView>
    );
};
