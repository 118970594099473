import React, { useContext, useMemo, useState } from 'react';
import { Modal, View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import SelectDropdown from 'react-native-select-dropdown';
import { useTranslation } from '@src/hooks/useTranslation';
import { paddingLeft, paddingRight, theme } from '@src/styles';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { INSTANCE_TYPES } from '@src/constants';
import Ionicons from '@expo/vector-icons/Ionicons';
import { STATUSES } from '@src/components/shared-components/Status';
import { REMOVE_LOGGER_SCREENS } from '../constant';

const styles = StyleSheet.create({
    backDrop: {
        backgroundColor: theme.palette.common.blackTransparent,
        height: '100%',
        width: '100%',
    },
    buttonsBlock: {
        paddingTop: 10,
    },
    dropdownBtnStyle: {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.common.borderGray,
        borderRadius: 8,
        borderWidth: 1,
        height: 50,
        marginTop: 24,
        width: '100%',
    },
    dropdownBtnTxtStyle: {
        color: theme.palette.common.borderGray,
        textAlign: 'left',
    },
    dropdownDropdownStyle: {
        backgroundColor: theme.palette.secondary[100],
        borderRadius: 4,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
        height: 160,
    },
    dropdownRowStyle: {
        backgroundColor: theme.palette.common.white,
        borderBottomColor: theme.palette.common.transparent,
    },
    dropdownRowTxtStyle: {
        color: theme.palette.common.black,
        paddingBottom: 10,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 10,
        textAlign: 'left',
    },
    dropdownWrapper: {
        flex: 1,
    },
    inputError: {
        color: theme.palette.error.main,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginTop: 16,
    },
    modalContent: {
        backgroundColor: theme.palette.common.white,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        flex: 1,
        paddingLeft,
        paddingRight,
        paddingTop: 35,
    },
    modalWrapper: {
        bottom: 0,
        minHeight: '55%',
        paddingLeft: 4,
        paddingRight: 4,
        position: 'absolute',
        width: '100%',
    },
    text: {
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.15,
        lineHeight: 24,
        textAlign: 'center',
    },
    title: {
        color: theme.palette.common.black,
        fontFamily: 'RobotoMedium',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: '500',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginBottom: 17,
        textAlign: 'center',
    },
});

const REMOVE_LOGGER_REASONS = [
    'ASSET_REMOVAL_ULD_NOT_IN_USE',
    'ASSET_REMOVAL_ULD_NEEDS_REPAIR',
    'ASSET_REMOVAL_LOGGER_DAMAGED',
    'ASSET_REMOVAL_LOGGER_BATTERY_LOW',
    'ASSET_REMOVAL_LOGGER_FALLOUT',
    'ASSET_REMOVAL_LOGGER_NUMBER_INCORRECT',
];

export const ConfirmModal = ({ navigation, isVisible, onClose, type, setStatus }) => {
    const { t } = useTranslation();
    const { logger, asset } = useContext(RemoveLoggerContext);
    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);
    const { FlexibleRequest: removePairing } = useBackendEndpoints()?.requests || {};
    const [reason, setReason] = useState('');
    const [inputError, setInputError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (selectedItem) => {
        setReason(selectedItem);
        if (inputError) {
            setInputError('');
        }
    };

    const remove = async () => {
        if (!reason) {
            setInputError(t('COMMON.FIELD_REQUIRED'));
            return;
        }

        setIsLoading(true);

        const result = await removePairing(
            'POST',
            // eslint-disable-next-line max-len
            `/assets/${instance.assetId}/action/remove-logger?loggerNumber=${instance.loggerNumber}&removalReason=${reason}`,
            {},
        );

        if (result && result.status === 200) {
            onClose();
            navigation.navigate(REMOVE_LOGGER_SCREENS.REMOVAL_SUCCESS, { type });
        } else {
            setStatus(STATUSES.ERROR);
            onClose();
        }

        setIsLoading(false);
    };

    return (
        <Modal
            animationType="fade"
            visible={isVisible}
            transparent
        >
            <TouchableOpacity
                activeOpacity={1}
                onPress={onClose}
                style={styles.backDrop}
            />
            <View style={styles.modalWrapper}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>{t('REMOVE_LOGGER.MODAL_TITLE')}</Text>
                    <Text style={styles.text}>{t('REMOVE_LOGGER.MODAL_NO_REVERT')}</Text>
                    <Text style={styles.text}>{t('REMOVE_LOGGER.MODAL_REASON')}</Text>

                    <View style={styles.dropdownWrapper}>
                        <SelectDropdown
                            dropdownOverlayColor="transparent"
                            data={REMOVE_LOGGER_REASONS}
                            onSelect={onChange}
                            buttonTextAfterSelection={(selectedItem) => t(`REMOVAL_REASONS.${selectedItem}`)}
                            defaultButtonText="Select"
                            buttonStyle={styles.dropdownBtnStyle}
                            buttonTextStyle={styles.dropdownBtnTxtStyle}
                            renderDropdownIcon={isOpened => {
                                return (
                                    <Ionicons
                                        name={isOpened ? 'caret-up-sharp' : 'caret-down-sharp'}
                                        color={theme.palette.common.dropdownGray}
                                        size={18}
                                    />
                                );
                            }}
                            dropdownIconPosition="right"
                            dropdownStyle={styles.dropdownDropdownStyle}
                            rowStyle={styles.dropdownRowStyle}
                            rowTextStyle={styles.dropdownRowTxtStyle}
                            renderCustomizedRowChild={(item) => {
                                return (
                                    <Text style={styles.dropdownRowTxtStyle}>{t(`REMOVAL_REASONS.${item}`)}</Text>
                                );
                            }}
                        />

                        {inputError && <Text style={styles.inputError}>{inputError}</Text>}
                    </View>

                    <ButtonsBlock
                        primaryBtnText={isLoading
                            ? <ActivityIndicator size="small" color={theme.palette.common.white} />
                            : t('COMMON.REMOVE')}
                        isPrimaryBtnDisabled={isLoading}
                        secondaryBtnText={t('COMMON.CANCEL')}
                        primaryBtnOnPress={remove}
                        secondaryBtnOnPress={onClose}
                        style={styles.buttonsBlock}
                    />
                </View>
            </View>
        </Modal>
    );
};
