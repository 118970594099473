import React, { useContext } from 'react';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { SuccessScreen } from '@src/components/SuccessScreen';

export const PairingSuccess = ({ navigation }) => {
    const { asset, logger } = useContext(AddLoggerContext);

    return (
        <SuccessScreen navigation={navigation}>
            <Status
                status={STATUSES.PAIRED_SUCCESS}
                assetNumber={asset.number}
                loggerNumber={logger.number}
                assetTypeCode={asset.assetTypeCode}
            />
        </SuccessScreen>
    );
};
