import { I18n } from 'i18n-js';
import enTranslations from '@assets/translations/en/translations.json';
import deTranslations from '@assets/translations/de/translations.json';
import { Platform } from 'react-native';

const translations = {
    en: enTranslations,
    de: deTranslations,
};

const i18n = new I18n(translations);

if (Platform.OS === 'web') {
    const urlParams = new URLSearchParams(window.location.search);
    const showTranslationCodes = urlParams.get('showTranslationCodes');

    if (showTranslationCodes === 'true') {
        i18n.t = (scope: string) => scope;
    }
}

i18n.locale = 'en';
i18n.enableFallback = true;
i18n.defaultLocale = 'en';

const t = i18n.t.bind(i18n);

export { i18n, t };
