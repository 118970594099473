import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Button } from '@src/components/shared-components/Button';
import { paddingBottom } from '@src/styles';

const styles = StyleSheet.create({
    btnFirstChild: {
        marginBottom: 16,
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom,
        paddingTop: 50,
    },
});

export const ButtonsBlock = ({
    primaryBtnText = '',
    primaryBtnOnPress = () => {},
    isPrimaryBtnDisabled = false,
    secondaryBtnText = '',
    secondaryBtnOnPress = () => {},
    isSecondaryBtnDisabled = false,
    style = {},
}) => {
    return (
        <View style={[styles.wrapper, style]}>
            {primaryBtnText && (
                <Button
                    style={styles.btnFirstChild}
                    onPress={primaryBtnOnPress}
                    variant="primary"
                    disabled={isPrimaryBtnDisabled}
                >
                    {primaryBtnText}
                </Button>
            )}
            {secondaryBtnText && (
                <Button
                    onPress={secondaryBtnOnPress}
                    disabled={isSecondaryBtnDisabled}
                >
                    {secondaryBtnText}
                </Button>
            )}
        </View>

    );
};
