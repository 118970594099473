export const ACCESS_TOKEN = 'accessToken';
export const USER_INFO = 'userInfo';

export const ASSET_TYPE_CODES = {
    CONTAINER: 'CONTAINER',
    PALLET: 'AIRPORT_PALLET',
};

export const INSTANCE_TYPES = {
    ASSET: 'asset',
    LOGGER: 'logger',
};
