import React from 'react';
import { RemoveLoggerContextWrapper } from './RemoveLoggerContext';
import { RemoveLogger } from './RemoveLogger';

export const RemoveLoggerPath = () => {
    return (
        <RemoveLoggerContextWrapper>
            <RemoveLogger />
        </RemoveLoggerContextWrapper>
    );
};
