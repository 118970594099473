import { INSTANCE_TYPES } from '@src/constants';
import { STATUSES } from '@src/components/shared-components/Status';

type Params = {
  type: 'asset' | 'logger',
  data: {
    loggerNumber?: string,
    assetNumber?: string,
  };
  setStatus: (status: string) => void,
  };

export const getStatus = ({ type, data = {}, setStatus }: Params) => {
    if (type === INSTANCE_TYPES.ASSET) {
        const { assetNumber, loggerNumber } = data;

        if (!assetNumber) {
            setStatus(STATUSES.NOT_FOUND);
        } else if (assetNumber && loggerNumber) {
            setStatus(STATUSES.PAIRED);
        } else if (assetNumber && !loggerNumber) {
            setStatus(STATUSES.IDENTIFIED);
        }
    } else if (type === INSTANCE_TYPES.LOGGER) {
        const { loggerNumber, assetNumber } = data;

        if (!loggerNumber) {
            setStatus(STATUSES.NOT_FOUND);
        } else if (loggerNumber && assetNumber) {
            setStatus(STATUSES.PAIRED);
        } else if (loggerNumber && !assetNumber) {
            setStatus(STATUSES.IDENTIFIED);
        }
    }
};
