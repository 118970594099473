import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SCREEN_NAMES } from '@src/Root/constant';
import { theme } from '@src/styles';
import { EnterAssetCode } from './EnterAssetCode';
import { ADD_LOGGER_SCREENS } from './constant';
import { ScanAssetCode } from './ScanAssetCode';
import { CheckAsset, CheckLogger } from './CheckAssetOrLogger';
import { EnterLoggerCode } from './EnterLoggerCode';
import { ScanLoggerCode } from './ScanLoggerCode';
import { PairingConfirm } from './PairingConfirm';
import { PairingSuccess } from './PairingSuccess';

const Stack = createNativeStackNavigator();

export const AddLoggerStack = () => {
    return (
        <Stack.Navigator screenOptions={{
            title: SCREEN_NAMES.ADD_LOGGER,
            headerShown: false,
            contentStyle: {
                backgroundColor: theme.palette.common.white,
            },
        }}
        >
            <Stack.Screen name={ADD_LOGGER_SCREENS.ENTER_ASSET_CODE} component={EnterAssetCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.SCAN_ASSET_CODE} component={ScanAssetCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.CHECK_ASSET_CODE} component={CheckAsset} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE} component={EnterLoggerCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE} component={ScanLoggerCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE} component={CheckLogger} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.PAIRING_CONFIRM} component={PairingConfirm} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.PAIRING_SUCCESS} component={PairingSuccess} />
        </Stack.Navigator>
    );
};
