import React, { useContext, useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from '@src/hooks/useTranslation';
import { paddingLeft, paddingRight, theme } from '@src/styles';
import { Input } from '@src/components/shared-components/Input';
import { INSTANCE_TYPES } from '@src/constants';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { enterCodeScreenStyles as sharedStyles } from '../styles';
import { REMOVE_LOGGER_SCREENS } from '../constant';

export const styles = StyleSheet.create({
    devider: {
        color: theme.palette.common.contrastGray600,
        fontFamily: 'Roboto',
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.18,
        lineHeight: 24,
        marginBottom: 16,
        marginTop: 35,
        textAlign: 'center',
    },
    error: {
        color: theme.palette.error.main,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: 0.15,
        lineHeight: 24,
        marginBottom: 16,
        marginTop: 35,
        textAlign: 'center',
    },
});

export const EnterCode = ({ navigation }) => {
    const { t } = useTranslation();
    const { setLogger, setAsset, stepper } = useContext(RemoveLoggerContext);
    const [error, setError] = useState('');
    const [loggerNumber, setLoggerNumber] = useState('');
    const [assetNumber, setAssetNumber] = useState('');

    const onSearch = () => {
        if ((loggerNumber && assetNumber) || (!loggerNumber && !assetNumber)) {
            setError(t('REMOVE_LOGGER.ENTER_CODE_ERROR_BOTH'));
            return;
        }

        if (loggerNumber) {
            setLogger({ number: loggerNumber });
            navigation.navigate(REMOVE_LOGGER_SCREENS.CHECK_CODE, { type: INSTANCE_TYPES.LOGGER });
        } else if (assetNumber) {
            setAsset({ number: assetNumber });
            navigation.navigate(REMOVE_LOGGER_SCREENS.CHECK_CODE, { type: INSTANCE_TYPES.ASSET });
        }

        stepper.nextStep();
    };

    const onScanCode = () => {};

    useEffect(() => {
        setError('');
    }, [loggerNumber, assetNumber]);

    return (
        <>
            <View style={sharedStyles.wrapper}>
                <Input
                    label={t('REMOVE_LOGGER.ENTER_LOGGER_NUMBER')}
                    value={loggerNumber}
                    onChangeText={(value) => setLoggerNumber(value)}
                    placeholder="EX. 1456 3456 6464 8754"
                    placeholderTextColor="#D7D7D7"

                />

                <Text style={styles.devider}>{t('REMOVE_LOGGER.OR')}</Text>

                <Input
                    label={t('REMOVE_LOGGER.ENTER_ASSET_NUMBER')}
                    value={assetNumber}
                    onChangeText={(value) => setAssetNumber(value)}
                    placeholder="EX. AAA 000 00 AF"
                    placeholderTextColor="#D7D7D7"

                />

                {error && <Text style={styles.error}>{error}</Text> }

            </View>
            <ButtonsBlock
                primaryBtnText={t('COMMON.SEARCH')}
                primaryBtnOnPress={onSearch}
                secondaryBtnText={t('COMMON.SCAN_CODE')}
                secondaryBtnOnPress={onScanCode}
                style={{ paddingLeft, paddingRight }}
            />
        </>
    );
};
