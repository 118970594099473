import React, { useContext, useEffect } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { StoreContext } from '@src/Contexts/StoreContext';
import { paddingBottom, paddingLeft, paddingRight, theme } from '@src/styles';
import { SCREEN_NAMES } from '@src/Root/constant';
import { Button } from '@src/components/shared-components/Button';
import { useTranslation } from '@src/hooks/useTranslation';

const styles = StyleSheet.create({
    btnWrapper: {
        flex: 1,
        justifyContent: 'flex-end',
        paddingLeft,
        paddingRight,
    },
    image: {
        height: 200,
        width: 200,
    },
    imageWrapper: {
        alignItems: 'center',
        flex: 1,
        flexGrow: 2,
        justifyContent: 'center',
    },
    wrapper: {
        backgroundColor: theme.palette.primary.deepBlue,
        flex: 1,
        paddingBottom,
    },
});

export const SuccessScreen = ({ navigation, children }) => {
    const { t } = useTranslation();
    const { layout, setLayout } = useContext(StoreContext);

    useEffect(() => {
        setLayout({ ...layout, isFullScreen: true });
    }, []);

    const onPress = () => {
        setLayout({ ...layout, isFullScreen: false });
        navigation.reset({ index: 0, routes: [{ name: SCREEN_NAMES.ACTIONS }] });
    };

    return (
        <View style={styles.wrapper}>
            <View style={styles.imageWrapper}>
                <Image
                    style={styles.image}
                    source={require('@assets/images/task-alt.png')}
                />
            </View>

            {children}

            <View style={styles.btnWrapper}>
                <Button
                    onPress={onPress}
                    variant="white"
                >
                    {t('COMMON.MAIN_MENU')}
                </Button>
            </View>
        </View>
    );
};
