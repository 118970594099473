import 'react-native-gesture-handler';
import React, { useCallback, useEffect } from 'react';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { Store } from '@src/Contexts/StoreContext';
import { Root } from './src/Root';

SplashScreen.preventAutoHideAsync();

export default function App() {
    const [fontsLoaded] = useFonts({
        Roboto: require('./assets/fonts/Roboto/Roboto-Regular.ttf'),
        RobotoMedium: require('./assets/fonts/Roboto/Roboto-Medium.ttf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    useEffect(() => {
        onLayoutRootView();
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }

    return (
        <Store>
            <Root />
        </Store>
    );
}
