import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { t } from '@src/i18n';
import { StoreContext } from '@src/Contexts/StoreContext';
import { paddingTop } from '@src/styles';
import { Stepper } from '@src/components/shared-components/Stepper';
import { AddLoggerStack } from './Screens/Stack';
import { AddLoggerContext } from './AddLoggerContext';

const styles = StyleSheet.create({
    stepperWrapper: {
        paddingTop,
    },
    wrapper: {
        flex: 1,
    },
});

const stepperLabels = [
    t('STEPPER.ASSET'),
    t('STEPPER.LOGGER'),
    t('STEPPER.PAIRING'),
];

export const AddLogger = () => {
    const { layout } = useContext(StoreContext);
    const { stepper } = useContext(AddLoggerContext);

    return (
        <View style={styles.wrapper}>
            {!layout.isFullScreen
            && (
                <View style={styles.stepperWrapper}>
                    <Stepper labels={stepperLabels} stepperData={stepper} />
                </View>
            )}
            <AddLoggerStack />
        </View>
    );
};
