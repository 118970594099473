import React from 'react';
import { AddLoggerContextWrapper } from './AddLoggerContext';
import { AddLogger } from './AddLogger';

export const AddLoggerPath = () => {
    return (
        <AddLoggerContextWrapper>
            <AddLogger />
        </AddLoggerContextWrapper>
    );
};
