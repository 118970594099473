import React, { useContext } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import { Button } from '@src/components/shared-components/Button';
import { paddingLeft, paddingRight, theme } from '@src/styles';
import { StoreContext } from '@src/Contexts/StoreContext';

const styles = StyleSheet.create({
    logo: {
        height: 137,
        width: 126,
    },
    logoWrapper: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
    title: {
        color: theme.palette.common.white,
        fontFamily: 'Roboto',
        fontSize: 34,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 36,
        marginTop: 20, /* 105.882% */
    },
    wrapper: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.deepBlue,
        flex: 1,
        justifyContent: 'flex-end',
        paddingBottom: 70,
        paddingLeft,
        paddingRight,
    },
});

export const Login = () => {
    const { auth } = useContext(StoreContext);
    const { login } = auth;

    return (
        <View style={styles.wrapper}>
            <View style={styles.logoWrapper}>
                <Image
                    source={require('@assets/images/logo_big.png')}
                    style={styles.logo}
                />
                <Text style={styles.title}>SkyTrack</Text>
            </View>
            <Button
                onPress={() => login()}
                variant="primary-dark"
            >
                Login
                {/* TODO: fix warning  */}
            </Button>
        </View>
    );
};
